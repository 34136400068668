import { StickerVariant } from '@canalplus/dive';
import { ApiV2SpyroStrateContentSticker } from '@dce-front/hodor-types/api/v2/common/dto/stickers/definitions';

export const MAP_HODOR_STICKER_TYPE_TO_STICKER_VARIANT: Record<
  ApiV2SpyroStrateContentSticker['type'],
  StickerVariant
> = {
  'offered-content': StickerVariant.OfferedContent,
  'free-to-air': StickerVariant.FreeToAir,
  'last-days': StickerVariant.LastDays,
  'get': StickerVariant.Get,
  'subscribe': StickerVariant.Subscribe,
  'live': StickerVariant.Live,
  'date': StickerVariant.Date,
};
