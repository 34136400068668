import { cva } from 'class-variance-authority';

export const stickerCVA = cva('rounded-dt-radius-small flex h-fit', {
  variants: {
    variant: {
      live: undefined,
      date: undefined,
      subscribe: undefined,
      get: undefined,
      'offered-content': undefined,
      'free-to-air': undefined,
      order: undefined,
      'last-days': undefined,
      duration: undefined,
    },
    isTv: {
      true: 'px-dt-spacing-75',
      false: 'px-dt-spacing-50',
    },
    iconPosition: {
      right: 'flex-row-reverse',
      left: undefined,
    },
  },
  compoundVariants: [
    {
      isTv: false,
      variant: 'live',
      className: 'bg-dt-theme-surface-sticker-sticker-live',
    },
    {
      isTv: true,
      variant: 'live',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-live',
    },
    {
      isTv: false,
      variant: 'date',
      className: 'bg-dt-theme-surface-sticker-sticker-date',
    },
    {
      isTv: true,
      variant: 'date',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-date',
    },
    {
      isTv: false,
      variant: 'subscribe',
      className: 'bg-dt-theme-surface-sticker-sticker-subscribe',
    },
    {
      isTv: true,
      variant: 'subscribe',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-subscribe',
    },
    {
      isTv: false,
      variant: 'get',
      className: 'bg-dt-theme-surface-sticker-sticker-get',
    },
    {
      isTv: true,
      variant: 'get',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-get',
    },
    {
      isTv: false,
      variant: 'offered-content',
      className: 'bg-dt-theme-surface-sticker-sticker-offered-content',
    },
    {
      isTv: true,
      variant: 'offered-content',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-offered-content',
    },
    {
      isTv: false,
      variant: 'free-to-air',
      className: 'bg-dt-theme-surface-sticker-sticker-free-to-air',
    },
    {
      isTv: true,
      variant: 'free-to-air',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-free-to-air',
    },
    {
      isTv: false,
      variant: 'order',
      className: 'bg-dt-theme-surface-sticker-sticker-order',
    },
    {
      isTv: true,
      variant: 'order',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-order',
    },
    {
      isTv: false,
      variant: 'last-days',
      className: 'bg-dt-theme-surface-sticker-sticker-last-days',
    },
    {
      isTv: true,
      variant: 'last-days',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-last-days',
    },
    {
      isTv: false,
      variant: 'duration',
      className: 'bg-dt-theme-surface-sticker-sticker-duration',
    },
    {
      isTv: true,
      variant: 'duration',
      className: 'bg-dt-theme-tv-surface-sticker-sticker-duration',
    },
  ],
});

export const stickerIconCVA = cva('', {
  variants: {
    isTv: {
      true: 'h-16',
      false: 'h-[0.6rem]',
    },
    variant: {
      live: undefined,
      date: undefined,
      subscribe: undefined,
      get: undefined,
      'offered-content': undefined,
      'free-to-air': undefined,
      order: undefined,
      'last-days': undefined,
      duration: undefined,
    },
  },
  compoundVariants: [
    {
      isTv: true,
      variant: [
        'live',
        'date',
        'subscribe',
        'get',
        'offered-content',
        'free-to-air',
        'order',
        'last-days',
        'duration',
      ],
      className: 'fill-dt-theme-tv-icon-sticker-icon',
    },
    {
      isTv: false,
      variant: [
        'live',
        'date',
        'subscribe',
        'get',
        'offered-content',
        'free-to-air',
        'order',
        'last-days',
        'duration',
      ],
      className: 'fill-dt-theme-icon-sticker-icon',
    },
  ],
});

export const stickerLabelCVA = cva(
  [
    'text-ellipsis overflow-hidden whitespace-nowrap',
    'm-dt-spacing-none', // ! temporary fallback for missing `@tailwind base`
  ],
  {
    variants: {
      isTv: {
        true: [
          'text-dt-font-size-24 leading-dt-font-line-height-28',
          'pt-3 pb-1', // ! waiting for tokens from studio
        ],
        false: [
          'text-dt-font-size-12 leading-dt-font-line-height-16',
          'pt-dt-spacing-25',
        ],
      },
      variant: {
        live: [
          'font-dt-font-family-system font-dt-font-weight-semibold',
          'pb-dt-spacing-none',
        ],
        date: [
          'font-dt-font-family-system font-dt-font-weight-regular',
          'pb-dt-spacing-none',
        ],
        subscribe: [
          'font-dt-font-family-system font-dt-font-weight-semibold',
          'pb-dt-spacing-none',
        ],
        get: [
          'font-dt-font-family-system font-dt-font-weight-semibold',
          'pb-dt-spacing-none',
        ],
        'offered-content': [
          'font-dt-font-family-system font-dt-font-weight-semibold',
          'pb-dt-spacing-25',
        ],
        'free-to-air': [
          'font-dt-font-family-system font-dt-font-weight-regular',
          'pb-dt-spacing-25',
        ],
        order: [
          'font-dt-font-family-system font-dt-font-weight-semibold',
          'pb-dt-spacing-none',
        ],
        'last-days': [
          'font-dt-font-family-system font-dt-font-weight-semibold',
          'pb-dt-spacing-none',
        ],
        duration: [
          'font-dt-font-family-system font-dt-font-weight-regular',
          'pb-dt-spacing-none',
        ],
      },
      iconPosition: {
        right: 'flex-row-reverse',
        left: undefined,
      },
      hasIcon: {
        true: undefined,
        false: undefined,
      },
    },
    compoundVariants: [
      {
        hasIcon: true,
        iconPosition: 'left',
        className: 'ml-dt-spacing-50',
      },
      {
        hasIcon: true,
        iconPosition: 'right',
        className: 'mr-dt-spacing-50',
      },
      {
        isTv: false,
        variant: 'live',
        className: 'text-dt-theme-text-sticker-sticker-live',
      },
      {
        isTv: true,
        variant: 'live',
        className: 'text-dt-theme-tv-text-sticker-sticker-live',
      },
      {
        isTv: false,
        variant: 'date',
        className: 'text-dt-theme-text-sticker-sticker-date',
      },
      {
        isTv: true,
        variant: 'date',
        className: 'text-dt-theme-tv-text-sticker-sticker-date',
      },
      {
        isTv: false,
        variant: 'subscribe',
        className: 'text-dt-theme-text-sticker-sticker-subscribe',
      },
      {
        isTv: true,
        variant: 'subscribe',
        className: 'text-dt-theme-tv-text-sticker-sticker-subscribe',
      },
      {
        isTv: false,
        variant: 'get',
        className: 'text-dt-theme-text-sticker-sticker-get',
      },
      {
        isTv: true,
        variant: 'get',
        className: 'text-dt-theme-tv-text-sticker-sticker-get',
      },
      {
        isTv: false,
        variant: 'offered-content',
        className: 'text-dt-theme-text-sticker-sticker-offered-content',
      },
      {
        isTv: true,
        variant: 'offered-content',
        className: 'text-dt-theme-tv-text-sticker-sticker-offered-content',
      },
      {
        isTv: false,
        variant: 'free-to-air',
        className: 'text-dt-theme-text-sticker-sticker-free-to-air',
      },
      {
        isTv: true,
        variant: 'free-to-air',
        className: 'text-dt-theme-tv-text-sticker-sticker-free-to-air',
      },
      {
        isTv: false,
        variant: 'order',
        className: 'text-dt-theme-text-sticker-sticker-order',
      },
      {
        isTv: true,
        variant: 'order',
        className: 'text-dt-theme-tv-text-sticker-sticker-order',
      },
      {
        isTv: false,
        variant: 'last-days',
        className: 'text-dt-theme-text-sticker-sticker-last-days',
      },
      {
        isTv: true,
        variant: 'last-days',
        className: 'text-dt-theme-tv-text-sticker-sticker-last-days',
      },
      {
        isTv: false,
        variant: 'duration',
        className: 'text-dt-theme-text-sticker-sticker-duration',
      },
      {
        isTv: true,
        variant: 'duration',
        className: 'text-dt-theme-tv-text-sticker-sticker-duration',
      },
    ],
  }
);
